import { Injectable } from "@angular/core";
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService, NbWindowService } from "@nebular/theme";
import { ConfirmDialogComponent } from "../components/@dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SaveDialogComponent, SaveDialogResult } from "../components/@dialogs/save-dialog/save-dialog.component";
import { SettingsDialogComponent } from "../components/@dialogs/settings-dialog/settings-dialog.component";
import { EntityType } from "../../shared-gen/Model/Utils/EntityType";
import { Entity } from "../../shared-gen/Model/Utils/Entity";
import { Dependencies } from "../../shared-gen/Model/Utils/Dependencies";
import { UpdateDependenciesDialogComponent } from "../components/@dialogs/update-dependencies-dialog/update-dependencies-dialog.component";
import { cloneDeep } from "../../shared/utils/core-utils";
import { Localized } from "../../shared/localized";
import { TranslationService } from "../../shared/services/translation.service";
import { MessageDialogComponent } from "../components/@dialogs/message-dialog/message-dialog.component";
import { IDChecker } from "../components/@dialogs/settings-dialog/id-checker";
import { JsonViewerComponent } from "app/components/json-viewer/json-viewer.component";
import { toLocalDate } from "shared/date";
import { DeviceOverview } from "shared-gen/Model/Devices/DeviceOverview";
import { DataDialogComponent } from "app/components/@dialogs/data-dialog/data-dialog.component";

@Injectable({
    providedIn: "root"
})
export class GuiService extends Localized {

    constructor(private toastrService: NbToastrService,
        private dialog: MatDialog,
        private windowService: NbWindowService,
        translationService: TranslationService) {
        super(translationService);
    }

    showToast(body: string, title: string = this.translate("🌐Messages.SuccessfullyProcessed"), status: NbComponentStatus = "success", duration: number = 3000) {
        const config = {
            status: status,
            destroyByClick: true,
            duration: duration,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false
        };

        this.toastrService.show(
            body,
            title,
            config);

    }

    showErrorMessageFromError(e: Error, title: string = null) {
        let message = e.message;
        // Treat known error reasons
        if (e["status"] == 401) {
            message = this.translate("🌐Messages.MissingAccessRights");
        }
        this.showErrorMessage(message, title);
    }

    showErrorMessage(message: string, title: string = null, durationInMilliseconds?: number) {
        this.showToast(message, title || this.translate("🌐General.Error"), "danger", durationInMilliseconds);
    }

    settingsDialog(entity: Entity, type: EntityType, callback: (result) => void, disableClose: boolean = false,
        idChecker: IDChecker | null = null) {
        let dialogRef: MatDialogRef<SettingsDialogComponent> = this.dialog.open(SettingsDialogComponent, {
            disableClose: disableClose,
            panelClass: "cdk-overlay-pane--custom"
        });
        dialogRef.componentInstance.entity = cloneDeep(entity);
        dialogRef.componentInstance.disableClose = disableClose;
        dialogRef.componentInstance.entityType = type;
        dialogRef.componentInstance.idChecker = idChecker;

        dialogRef.afterClosed().subscribe(result => {
            callback(result);
            dialogRef = null;
        });
    }

    showDialog(obj: any, component: any, callback: (result) => void, disableClose: boolean = false, data: any = null) {
        let dialogRef = this.dialog.open(component, {
            disableClose: disableClose,
            panelClass: "cdk-overlay-pane--custom"
        });
        let comp = dialogRef.componentInstance as any;
        comp.obj = cloneDeep(obj);
        comp.disableClose = disableClose;
        comp.data = cloneDeep(data);

        dialogRef.afterClosed().subscribe(result => {
            callback(result);
            dialogRef = null;
        });
    }

    messageDialog(title: string, body: string, callback: (result) => void = null) {
        let dialogRef: MatDialogRef<MessageDialogComponent> = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            panelClass: "cdk-overlay-pane--custom"
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = body;
        if (callback) {
            dialogRef.afterClosed().subscribe(async (result) => {
                callback(result);
                dialogRef = null;
            });
        }
        dialogRef.componentInstance.refresh();
    }

    // TODO: Dialog with data input
    //dataDialog(title: string, message: string, template: TemplateRef, context: any, callback: (result) => void) {
    // TODO: add input data to result



    dataDialog(title: string, body: string, callback: (result) => void) {
        let dialogRef: MatDialogRef<DataDialogComponent> = this.dialog.open(DataDialogComponent, {
            disableClose: false,
            panelClass: "cdk-overlay-pane--custom"
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.confirmMessage = body;
        dialogRef.afterClosed().subscribe(async (result) => {
            callback(result);
            dialogRef = null;
        });
    }


    confirmDialog(title: string, body: string, callback: (result) => void) {
        let dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: title,
                confirmMessage: body,
            },
            disableClose: false,
            panelClass: "cdk-overlay-pane--custom"
        });

       
        dialogRef.afterClosed().subscribe(async (result) => {
            callback(result);
            dialogRef = null;
        });
    }

    confirmDeleteDialog(callback: (result) => void, body: string = null, title: string = null) {
        this.confirmDialog(title || this.translate("🌐General.Confirm"),
            body || this.translate("🌐Questions.ConfirmDeleteEntity"), callback);
    }

    save<T extends Entity>(callback: (result: SaveDialogResult<T>) => void, entity: T, type: EntityType) {
        let dialogRef: MatDialogRef<SaveDialogComponent<T>> = this.dialog.open(SaveDialogComponent, {
            disableClose: false,
            panelClass: "cdk-overlay-pane--custom"
        }) as MatDialogRef<SaveDialogComponent<T>>;
        dialogRef.componentInstance.entity = cloneDeep(entity);
        dialogRef.componentInstance.entityType = type;

        dialogRef.afterClosed().subscribe(async (result) => {
            callback(result);
            dialogRef = null;
        });
    }

    showUpdateDependenciesDialog(callback: (isUpdateSelected: boolean) => void, entity: Entity, type: EntityType,
        dependencies: Dependencies) {
        let dialogRef: MatDialogRef<UpdateDependenciesDialogComponent> =
            this.dialog.open(UpdateDependenciesDialogComponent, {
                disableClose: false,
                panelClass: "cdk-overlay-pane--custom"
            });
        dialogRef.componentInstance.entity = cloneDeep(entity);
        dialogRef.componentInstance.entityType = type;
        dialogRef.componentInstance.dependencies = dependencies;

        dialogRef.afterClosed().subscribe(async (isUpdateSelected) => {
            callback(isUpdateSelected);
            dialogRef = null;
        });
    }

    async showDeviceReportJson(device: DeviceOverview) {
        try {
            this.windowService.open(JsonViewerComponent, {
                title: this.translate("🌐General.Report") + ": "
                    + (device.Report.LastTimeOnline ? toLocalDate(device.Report.LastTimeOnline) : this.translate("🌐General.Offline")),
                context: { device: device }
            });
        }
        catch {
        }
    }

}

import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../../services/data.service";
import { EntityType } from "../../../../shared-gen/Model/Utils/EntityType";
import { Entity } from "../../../../shared-gen/Model/Utils/Entity";
import { EntityFun } from "../../../../shared/model/utils/entity.fun";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { IDChecker } from "./id-checker";

@Component({
    selector: "ngx-menu-settings-dialog",
    templateUrl: "./settings-dialog.component.html",
    styleUrls: ["./settings-dialog.component.scss"]
})
export class SettingsDialogComponent extends Localized implements OnInit {

    public entity: Entity;
    public entityType: EntityType;

    idChecker: IDChecker | null = null;
    isIDOK = false;
    idErrorMessage = "";

    isNameSet = false;
    nameErrorMessage = "";

    disableClose: boolean = false;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<SettingsDialogComponent>,
        translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
        if (!this.entityType) throw new Error("No EntityType set!");
        this.checkID();
        this.checkName();
    }

    async checkID() {
        if (false == EntityFun.isIDValid(this.entity.ID)) {
            this.isIDOK = false;
            this.idErrorMessage = this.translate("🌐Settings.InvalidID");
        }
        else if (this.idChecker != null) {
            this.idErrorMessage = await this.idChecker.validate(this.entity.ID);
            this.isIDOK = this.idErrorMessage.length == 0;
        }
        else {
            this.isIDOK = true;
            this.idErrorMessage = "";
        }
    }

    checkName() {
        this.isNameSet = this.entity.Name?.length > 0 ?? false;
        this.nameErrorMessage = this.isNameSet ? "" : this.translate("🌐Settings.EnterName");
    }

    async save() {
        if (this.isIDOK && this.isNameSet) {
            this.dialogRef.close(this.entity);
        }
    }

}

<div class="row">

    <div class="col-6">
        <nb-card *ngIf="currentBase.Instances.length > 1 || (currentBase.Options && currentBase.Options.length > 0)"
            class="nb-double-nested-card">
            <nb-card-header>{{"🌐Products.Instance.GeneralInformation" | translate:lang}}</nb-card-header>
            <nb-card-body>

                <div *ngIf="currentBase.Options && currentBase.Options.length > 0" class="row align-items-center my-3">
                    <div class="col-4">
                        <label class="mb-0" for="variant-id">{{"🌐General.ID" | translate:lang}}:</label>
                    </div>
                    <div class="col-8">
                        <input id="variant-id" [(ngModel)]="currentInstance.ID" name="instID" nbInput fullWidth
                            placeholder="ID" disabled readonly />
                        <p *ngIf="!isValidID(currentInstance.ID)" class="error mb-0">
                            {{"Products.Instance.ChoicesNotFullySpecified" |
                            translate:lang}}</p>
                    </div>
                </div>

                <div *ngIf="currentBase.Options && currentBase.Options.length > 0" class="row my-3">
                    <div class="col-4 pt-2">
                        <label class="mb-0" for="name">{{"Products.Instance.Name" | translate:lang}}</label>
                    </div>
                    <div class="col-8">
                            <localized-string-input
                                inputName="name"
                                [(localizedString)]="productInstance.LocalizedNames"
                                [(value)]="productInstance.Name"
                                placeholder="{{('Products.Instance.Name' | translate: lang).replace(':','')}}"
                                [localizedPlaceholder]="productInstance.Name"
                                [disabled]="!isEditable"
                                [status]="'primary'"
                            ></localized-string-input>
                    </div>
                </div>

                <div *ngIf="currentBase.Options && currentBase.Options.length > 0" class="row my-3">
                    <div class="col-3">
                        <label class="mt-2 mb-3">{{"🌐Products.Instance.Options" | translate:lang}}</label>
                    </div>

                    <div class="col-6 offset-3">
                        <nb-select [selected]="instanceOptions[option.ID]"
                            [placeholder]='"🌐Products.Instance.OptionUndefined" | translate:lang'
                            (selectedChange)="changeOption(option, $event)" *ngFor="let option of currentBase.Options"
                            class="w-100 mb-3 text-right" [disabled]="!isEditable" [status]="'primary'">
                            <nb-option value="?">{{"🌐Products.Instance.OptionUndefined" | translate:lang}}</nb-option>
                            <nb-option [value]="choice.ID" *ngFor="let choice of option.Choices"
                                [disabled]="!choiceAvailable(option, choice)">{{choice.Name ? choice.Name :
                                choice.ID}}
                            </nb-option>
                        </nb-select>
                    </div>
                </div>

            </nb-card-body>
        </nb-card>

        <nb-card class="nb-double-nested-card" *ngIf="currentBase.ProductType == 'Eversys'">
            <nb-card-header>{{"🌐Products.Instance.Ingredients" | translate:lang}}</nb-card-header>
            <nb-card-body>
                <ng-container *ngFor="let resource of resources">
                    <div>
                        <nb-checkbox status="info" (change)="changeResource(resource)"
                            [checked]="currentInstance.Resources && currentInstance.Resources.indexOf(resource) !==-1"
                            [disabled]="!isEditable">
                            {{translateResource(resource)}}
                        </nb-checkbox>
                    </div>
                </ng-container>
            </nb-card-body>
        </nb-card>

        <nb-card class="nb-double-nested-card" *ngIf="currentBase.ProductType == 'Eversys'">
            <nb-card-header>{{"🌐Products.Instance.MachineData" | translate:lang}}</nb-card-header>
            <nb-card-body>

                <div class="row align-items-center my-3">

                    <div class="col-7">
                        <label class="mb-0" for="servierzeit">{{"🌐Products.Instance.ServingTimeInSeconds" |
                            translate:lang}}</label>
                    </div>

                    <div class="col-5">
                        <input id="servierzeit" [(ngModel)]="currentInstance.ServingTime" name="instServingTime"
                            placeholder="Servierzeit" nbInput fullWidth class="text-right"
                            [disabled]="!isEditable"
                            [status]="isEditable?'primary':'basic'" />
                    </div>
                </div>

                <div class="row align-items-center my-3">

                    <div class="col-7">
                        <label class="mb-0" for="machineid">{{"🌐Products.Instance.MachineNumber" |
                            translate:lang}}</label>
                    </div>

                    <div class="col-5">
                        <input id="machineid" [(ngModel)]="currentInstance.SelectionNumber" name="instServingTime"
                            placeholder="Nr." nbInput fullWidth class="text-right"
                            [disabled]="!isEditable"
                            [status]="isEditable?'primary':'basic'" />
                    </div>
                </div>

            </nb-card-body>
        </nb-card>

        <nb-card class="nb-double-nested-card" *ngIf="currentBase.ProductType == 'SandenVendoVM'">
            <nb-card-header>{{"🌐General.Attributes" | translate:lang}}</nb-card-header>
            <nb-card-body>
                <nb-checkbox status="info" [(ngModel)]="currentInstance.Cooled"
                    [disabled]="!isEditable">
                    {{"🌐General.NeedsCooling" | translate:lang}}
                </nb-checkbox>
            </nb-card-body>
        </nb-card>

        <nb-card class="nb-double-nested-card" *ngIf="currentBase.ProductType == 'SelfService'">
            <nb-card-header>{{"🌐Products.Instance.SelfServiceInformation" | translate:lang}}</nb-card-header>
            <nb-card-body>
                <button nbButton *ngIf="!orderProductQrCodeImage" (click)="triggerGenerateQrCommandOrderProduct()"
                        >{{"Products.Instance.GenerateOrderProductQrCode" | translate:lang}}</button>
                <span *ngIf="orderProductQrCodeImage">{{"Products.Instance.SelfServiceQrCodeDescription"| translate:lang}}</span>
                <img *ngIf="orderProductQrCodeImage" class="productInstance-orderProductQrCodeImage" [src]="orderProductQrCodeImage" />
            </nb-card-body>
        </nb-card>

    </div>

    <div class="col-6">

        <nb-card class="nb-double-nested-card">
            <nb-card-header>{{"🌐Products.Instance.BusinessData" | translate:lang}}</nb-card-header>
            <nb-card-body>

                <div class="row align-items-center my-3">
                    <div class="col-5">
                        <label class="mb-0" for="customID">{{"🌐Products.CustomID" | translate:lang}}</label>
                    </div>
                    <div class="col-7">
                        <input id="customID" [(ngModel)]="customID" name="instCustomID" placeholder="" nbInput fullWidth
                            class="text-right" [disabled]="!isEditable"
                            [status]="isEditable?'primary':'basic'" />
                    </div>
                </div>

                <ng-container *ngIf="projectName == 'tur'">
                    <div class="row align-items-center my-3">
                        <div class="col-5">
                            <label class="mb-0" for="sap-nr">{{"🌐Products.Instance.SAPNumber" |
                                translate:lang}}</label>
                        </div>
                        <div class="col-7">
                            <input id="sap-nr" [(ngModel)]="sapNumber" name="instSap" placeholder="SAP-Nummer" nbInput
                                fullWidth class="text-right" [disabled]="!isEditable"
                                [status]="isEditable?'primary':'basic'" />
                        </div>
                    </div>
                </ng-container>

                <div class="row align-items-center my-3">
                    <div class="col-5">
                        <label class="mb-0" for="ean">{{"🌐Products.Instance.EAN" | translate:lang}}</label>
                    </div>
                    <div class="col-7">
                        <input id="ean" [(ngModel)]="eanCode" name="instEan" placeholder="EAN" nbInput fullWidth
                            class="text-right" [disabled]="!isEditable"
                            [status]="isEditable?'primary':'basic'" />
                    </div>
                </div>

                <ng-container *ngIf="false && projectName != 'tur'"><!-- deactivate this feature because witzig got ended. Likely deprecated -->
                    <div class="row align-items-center my-3">
                        <div class="col-7">
                            <label>{{"🌐Products.PackageSize" | translate:lang}}:</label>
                        </div>
                        <div class="col-4">
                            <input [(ngModel)]="currentInstance.PackageSize" nbInput fullWidth [disabled]="!isEditable"
                                [status]="'primary'" />
                        </div>
                        <div class="col-1 text-left pl-0">{{smallResourceUnit}}</div>
                    </div>
                </ng-container>

            </nb-card-body>
        </nb-card>

        <nb-card class="nb-double-nested-card" *ngIf="!currentBase.ProductType.startsWith('Resource')">
            <nb-card-header>{{"🌐Products.Instance.PricingInformation" | translate:lang}}</nb-card-header>
            <nb-card-body>
                <div class="row align-items-center my-3">
                    <div class="col-7">
                        <label class="mb-0">{{"🌐Products.Instance.RecommendedGrossPrice" | translate:lang}}
                            <br />{{"🌐Products.Instance.SalesPriceIncludingVAT" | translate:lang}}</label>
                    </div>

                    <div class="col-5">
                        <ngx-price-input [(price)]="currentInstance.Price"
                            [inputDisabled]="!isEditable"></ngx-price-input>
                    </div>
                </div>

                <div class="row align-items-center my-3">
                    <div class="col-7">
                        <label class="mb-0">{{"🌐Products.Instance.Deposit" | translate:lang}}</label>
                    </div>

                    <div class="col-5">
                        <ngx-price-input [(price)]="currentInstance.Deposit"
                            [inputDisabled]="!isEditable"></ngx-price-input>
                    </div>
                </div>

                <div class="row align-items-center my-3">
                    <div class="col-7">
                        <label class="mb-0">{{"🌐Products.Instance.VATRate" | translate:lang}}</label>
                    </div>
                    <div class="col-5">
                        <nb-select [selected]="currentInstance.Vat" (selectedChange)="changeVat($event)"
                            placeholder="MwSt." class="w-100 text-right" [disabled]="!isEditable"
                            [status]="isEditable?'primary':'basic'">
                            <nb-option [value]="0">0%</nb-option>
                            <nb-option [value]="5">5%</nb-option>
                            <nb-option [value]="7">7%</nb-option>
                            <nb-option [value]="10">10%</nb-option>
                            <nb-option [value]="16">16%</nb-option>
                            <nb-option [value]="19">19%</nb-option>
                            <nb-option [value]="23">23%</nb-option>
                        </nb-select>
                    </div>
                </div>

                <div class="row align-items-center my-3">
                    <div class="col-7">
                        <label class="mb-0">{{"🌐Products.Instance.RecommendedTotalPrice" | translate:lang}}</label>
                    </div>

                    <div class="col-5">
                        <ngx-price-input [(price)]="currentInstanceTotalPrice"
                            [inputDisabled]="!isEditable"></ngx-price-input>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>

    </div>

</div>
